import 'core-js/stable'
import 'regenerator-runtime/runtime'

import { start } from 'single-spa'
import { onlineOrdering } from '../registration/online-ordering'
import { checkForFlag } from '../utils'


// Type Window to be explicit on what we are accessing
interface Window {
  crossOriginIsolated?: boolean,
  OO_GLOBALS?: { 
    mode: string,
    shortUrl: string,
    routerBasename: string,
    restaurantGuid: string,
    enabledFeatureFlags: string[]
  },
  SharedArrayBuffer?: Function
}

const win: Window = window

// REMOVE ONCE WE UPDATE TO REACT 17.0.2
// Reference: https://github.com/facebook/create-react-app/issues/10474
if (!win.crossOriginIsolated) win.SharedArrayBuffer = ArrayBuffer

const el = document.createElement('div')
el.setAttribute('id', 'single-spa-application:root')
document.body.appendChild(el)
// TODO: Get Restaurant Info and other Globals
// Into the OO Layout SPA
onlineOrdering({ restaurantInfo: {shortUrl: win?.OO_GLOBALS?.shortUrl, restaurantGuid: win?.OO_GLOBALS?.restaurantGuid}, ooGlobals: win?.OO_GLOBALS && { ...win?.OO_GLOBALS } } )

// Add recaptcha script to header
if (checkForFlag({
  flagName: 'oo-google-captcha-enabled',
  enabledFeatureFlags: win.OO_GLOBALS?.enabledFeatureFlags || []
})) {
  const captchaScript = document.createElement('script')
  const googleCaptchaKey = process.env.GOOGLE_CAPTCHA_KEY || '6LdqU7AiAAAAAE1m_zsbjVTG-sglCPdcWB0K_i4t'
  captchaScript.setAttribute('src', `https://www.google.com/recaptcha/enterprise.js?render=${googleCaptchaKey}`)
  document.head.appendChild(captchaScript)
}

console.log('Root Config Registered')

start()
