import envFromHostname from '@toasttab/env-from-hostname'
import { envFromOrderHostname } from "@toasttab/do-secundo-env-from-orders-hostname";

export const getHeapConfig = (mode) => {
  let HEAP_ID
  switch (mode) {
    case 'OO':
      switch (envFromHostname(window.location.hostname)) {
        case 'dev':
          HEAP_ID = '2379410469'
          break
        case 'preprod':
          HEAP_ID = '1394490384'
          break
        case 'prod':
          HEAP_ID = '1926653669'
          break
        default:
          HEAP_ID = '1926653669'
          break
      }
      break
    case 'OPT':
    case 'OPT_PREVIEW':
    case 'STP':
    case 'MNP':
    case 'TTS':
    case 'QR':
    case 'SHORTENED':
      switch (envFromOrderHostname(window.location.hostname)) {
        case 'dev':
          HEAP_ID = '2772265470'
          break
        case 'preprod':
          HEAP_ID = '1715116224'
          break
        case 'prod':
          HEAP_ID = '963914065'
          break
        default:
          HEAP_ID = '963914065'
          break
      }
      break
  }

  return HEAP_ID
}

export const getSentryConfig = (mode) => {
  switch (mode) {
    case 'OO':
      return {
        publicKey: '33b889f63e6643cd9e970b491d574d5a@o37442',
        projectId: '1319042'
      }
    case 'OPT':
    case 'STP':
    case 'MNP':
    case 'TTS':
    case 'OPT_PREVIEW':
    case 'QR':
    case 'SHORTENED':
      return {
        publicKey: '874811933ffa44cab395a151481ae1ac@o37442',
        projectId: '6264217'
      }
    case 'CATERING':
      return {
        publicKey: '5be03d53ecf14292a7227f11cbe6362e@o37442',
        projectId: '6741432'
      }
  }
}
