import { routeMatcher } from "@toasttab/banquet-match-utils"

export const loyaltySpa = {
  name: 'corn-loyalty-spa',
  activeWhen: ({ pathname }) => {
    const checkoutMatcher = routeMatcher(['*/checkout', '*/tab', '*/tab/*'])
    return Boolean(checkoutMatcher(pathname))
  }
}
export const appToSpasMap = {
  QR: [
    {
      name: 'corn-layout-spa'
    }
  ],
  SHORTENED: [
    {
      name: 'corn-layout-spa'
    }
  ],
  CATERING: [
    {
      name: 'corn-layout-spa'
    }
  ],
  STP: [
    {
      name: 'corn-layout-spa'
    },
    {
      name: 'corn-guest-auth-spa'
    },
    loyaltySpa
  ],
  // covers OPT/MNP/TTS
  default: [
    {
      name: 'corn-layout-spa'
    },
    {
      name: 'corn-menu-spa'
    },
    {
      name: 'corn-tabs-spa'
    },
    {
      name: 'corn-guest-auth-spa'
    },
    loyaltySpa
  ]
}
