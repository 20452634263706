import { registerApplication } from 'single-spa'
import { importSpa } from '../utils'
import { envFromOrderHostname } from '@toasttab/do-secundo-env-from-orders-hostname'
import { appToSpasMap } from './spas'

export const onlineOrdering = ({ restaurantInfo, ooGlobals }) => {
  const environment = envFromOrderHostname(window.location.hostname)
  const { mode } = ooGlobals

  const globalCustomProps = {
    restaurantInfo: {
      ...restaurantInfo
    },
    mode,
    menuVisibility: 'KIOSK',
    shortUrl: restaurantInfo.shortUrl,
    restaurantGuid: restaurantInfo.restaurantGuid,
    ooGlobals: ooGlobals
  }

  const spasToRegister = appToSpasMap[mode] || appToSpasMap.default

  spasToRegister.forEach(({ name, activeWhen }) => {
    registerApplication({
      name,
      activeWhen: activeWhen || (() => true),
      customProps: globalCustomProps,
      app: importSpa(name)
    })
  })

  if (environment !== 'prod') {
    registerApplication({
      name: 'spa-dev-tools',
      activeWhen: () => true,
      customProps: globalCustomProps,
      app: importSpa('spa-dev-tools')
    })
  }
}
