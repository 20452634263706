export const checkForFlag = ({ flagName, enabledFeatureFlags, defaultValue = false }) => {
    if (
        Array.isArray(enabledFeatureFlags) &&
        enabledFeatureFlags.includes(flagName)
    ) {
        return true
    }
    return defaultValue
}

export const isInSpaControl = ({ enabledFeatureFlags, defaultValue = false }) =>
    checkForFlag({
        flagName: 'oo-spa-control-import-map',
        enabledFeatureFlags,
        defaultValue
    })
